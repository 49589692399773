@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-bg-primary: #B2387B;
  // --bg-light: blue;
  // --bg-dark: red;
  --color-bg-case: var(--bg-light);
}

.text-text {
  color: var(--color-text, var(--color-gray-800, #2d3748));
}

.text-white {
  color: var(--color-white, #fff) !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-bg-case: var(--bg-dark);
    --color-text: #fff;
  }
}

.case {
  background: var(--color-bg-case);
}

.theme-lf {
  --bg-light: #fff;
  --bg-dark: #fff;
  --color-bg-case: var(--bg-light); /* Light mode */

  .bg-wavy {
    --size: 16px;
    --R: calc(var(--size)*1.28);
    -webkit-mask: radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%) calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%, radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%) 50% var(--size)/calc(4*var(--size)) 100% repeat-x;
    background: linear-gradient(90deg,#E7EFF6,#E7EFF6);
  }
}

@media (prefers-color-scheme: dark) {
  .theme-lf {
    --color-bg-case: var(--bg-dark); /* Dark mode */
    --color-text: var(--color-gray-800, #2d3748);
  }
}
