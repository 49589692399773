@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

area {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.soon {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #000,
      #000 50%,
      #000
    ),
    conic-gradient(from var(--border-angle), transparent 20%, rgba(255, 255, 255, 0.1), #C62A6F);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.Typewriter__cursor {
  color: #C1297D;
}

.scroll-container {
  display: none;
  @apply overflow-hidden;
  width: calc(100% - 40px);
  &:nth-of-type(1) {
    transform: rotate(0deg);
    margin: 0 auto;
  }
  &:nth-of-type(2) {
    transform: rotate(270deg);
    transform-origin: left;
    bottom: 0;
    left: auto;
    top: auto;
  }
  &:nth-of-type(3) {
    transform: rotate(0);
    margin: 0 auto;
    top: auto;
    bottom: 0;
  }
  &:nth-of-type(4) {
    transform: rotate(270deg);
    margin: auto 0;
    transform-origin: right;
  }
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  padding:4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  // border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  border: 0px solid transparent;
}

.social-icon:hover .social-svg-icon { fill: rgba(255, 255, 255, 1) !important; }
.social-icon:hover g, .social-icon:hover svg { fill: rgba(0, 0, 0, 1) !important; }

.social-icon--custom:hover {
  background: rgba(0,0,0,1) !important;
}

:root {
  --foundation: path("m 161 188.375 z m -19.5 -128.875 c -10.24 0 -23.564 -14.4372 -41 -7.5 c -18.8501 7.5 -14.4649 21.5325 -22 33.5 c -8.5 13.5 -20.5829 10.8659 -29 29.5 c -9.0341 20 9 23 9 39 c 0 16 -17.5 12.5 -12 31.5 c 5.5 19 26 10.5 35.1499 23.5 c 12.8501 9.5 0 24.418 18.8501 32.5 c 22.157 9.5 21 -9.5 41 -4.5 c 13 0 13 20 39 14 c 23.49 -5.421 13 -21 30.5 -28.5 c 15.5 -10 25.5 3.5 44 -16.5 c 17.51 -18.93 -7.5 -29 -6 -52 c 4 -21.5 24.5 -34 9.5 -60 c -13 -17 -23.5 -15.5 -47.5 -18.5 c -20.5 -7.5 -11 -22.5 -33.5 -26 c -24 -6 -16.5 10 -36 10 z z");
}

.splatted {
  --splodge: var(--foundation);
  height: 300px;
  width: 300px;
  position: relative;
}

.splatted-image {
  height: 100%;
  width: 100%;
  -webkit-clip-path: var(--foundation);
  clip-path: var(--foundation);
  transition: transform 0.2s, -webkit-clip-path 0.2s;
  transition: clip-path 0.2s, transform 0.2s;
  transition: clip-path 0.2s, transform 0.2s, -webkit-clip-path 0.2s;
  transform: rotate(calc(var(--rotate, 0)* 1deg)) scale(var(--scale, 1));
  position: relative;
  background-image: var(--img);
  background-position: calc(var(--x, 0)* 1px) calc(var(--y, 0)* 1px);
  background-color: hsl(var(--hue), 80%, 65%);
}

.full-circle {
  will-change: transform;
  overflow: hidden;
  clip-path: circle(55% at 70% 50%);
  width: 100%;
  height: 100%;
}

.full-circle-image {
  background-size: cover;
  will-change: transform;
  -webkit-backspace-visibility: hidden;
  background-image: var(--img);
}

.plektrum {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  overflow: hidden;
  // move to props
  width: 608px;
  height: 608px;
}

.image-fade-container img {
  transition: opacity 2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.image-fade-container img.active {
  opacity: 1;
  z-index: 10;
}

.image-fade-container img.next {
  opacity: 0;
}

body.dragging {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  pointer-events: none;
}

body.dragging .floating-button {
  pointer-events: auto;
} 

.slide .control-dots {
  position:relative;
}